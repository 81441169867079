@import "variables";

html,
body {
    height: 100%;
}

body {
    overflow-x: hidden;
    font-family: $ff-poppins;
    &.cherry-style {
        background-color: $cherry;
        color: $whiteText;
        h1, h2, h3, h4, h5, h6,
        .h1, .h2, .h3, .h4, .h5, .h6 {
            color: $light-yellow;
            background-color: $light-yellow;
        }
    }
    background-color: $whiteText;
    color: $cherry;
}

//Scrollbars
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-thumb {
    background: $light-yellow;
    &:hover {
        background: $yellow;
    }
}

::selection {
    color: $white;
    text-shadow: none;
}

//Headers
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    color: $cherry;
    font-family: $ff-poppins;
    font-weight: $font-weight-normal-plus;
    margin-bottom: 7.40740741vmin;
    background-color: $cherry;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 9.25925926vmin;
    line-height: 1;
}
//Paragraphs
p {
    @media (min-width: $bootstrap-grid-lg){
        text-indent: 3em;
    }
}
label {
    font-family: $ff-roboto;
}
.mt-40 {
    margin-top: 40px;
}
.mt-100 {
    margin-top: 100px;
}

*{
    scroll-behavior: smooth !important;
}

.page-content {
    @media (min-width: $bootstrap-grid-lg){
        margin-top: 95px;
    }
}