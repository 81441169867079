.menu-target {
	height: 10px;
	@media (min-width: $bootstrap-grid-lg){
		height: 100px;
	}
}
#home {
	height: 100px;
}
.logo-cherry {
	display: inline-block;
}
.logo-grey {
	display: none;
}
body.cherry-style {
	.logo-cherry {
		display: none;
	}
	.logo-grey {
		display: inline-block;
	}
	.navbar-light {
		background-color: $cherry;
		.navbar-nav {
			.nav-item {
				&.active {
					color: $white;
				}
			}
			.nav-link {
				text-decoration: none;
				color: $light-yellow;
				font-weight: normal;
				&:hover {
					color: $white;
					font-weight: bold;
				}
			}
		}
		.navbar-toggler {
			color: $whiteText;
			border-color: $whiteText;
			background-color: transparent;
			padding: 10px 15px;
		}
		border-bottom: 1px solid $grey;
	}
	.desktop-menu {
		ul {
			li {
				a {
					color: $light-yellow;
					&:hover {
						color: $white;
						opacity: 1;
					}
					.horizontal-line {
						background: linear-gradient($white, $white) no-repeat center/100% 1%;
					}
				}
				&.active {
					a {
						color: $white;
						opacity: 1;
					}
				}
			}
		}
	}
}
.navbar-light {
	background-color: $white;
	.navbar-nav .nav-link {
		text-decoration: none;
		color: $cherry;
		font-weight: normal;
		&:hover {
			color: $cherry;
			font-weight: bold;
		}
	}
	.navbar-toggler {
		color: $cherry;
		border-color: $cherry;
		background-color: transparent;
		padding: 10px 15px;
	}
	border-bottom: 1px solid $whiteText;
}

.desktop-menu {
	position: fixed;
	top: 0;
	width: 200px;
	height: 100%;
	padding-left: 3.75rem;
	ul {
		height: 100%;
		margin-left: 5px;
		li {
			transition: margin-top .5s linear;
			line-height: 30px;
			a {
				color: $cherry;
				text-decoration: none;
				&:hover {
					opacity: .8;
				}
				.horizontal-line {
					width: 0;
					display: inline-block;
					margin-top: 1px;
					background: linear-gradient($cherry, $cherry) no-repeat center/100% 1%;
					height: 1rem;
					margin-right: 0;
				}
			}
			&.active {
				&:not(:last-child) + li {
					margin-top: 55vh;
				}
				a {
					opacity: .8;
				}
				.horizontal-line {
					width: 1.875rem;
					margin-right: 1rem;
				}
			}
		}
	}
}