@import "variables";

.tab-divider {
	padding-left: .5em;
	@media (min-width: $bootstrap-grid-lg){
		padding-left: 1.5em;
	}
}

.btn-booking {
	font-family: $ff-poppins;
	background-color: $cherry;
	border: none;
	color: $white;
	padding: 15px 32px;
	text-align: center;
	display: inline-block;
	font-size: 16px;
	cursor: pointer;
	text-decoration: none !important;
	&:hover {
		color: $light-yellow;
	}
	&:focus {
		outline: none;
	}
}

.btn-contact {
	font-family: $ff-poppins;
	font-size: 16px;
	color: $cherry;
	padding: 10px 32px;
}